import React from 'react';
import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN, AUTH_WORKSPACE, AUTH_USER_ID } from '../../shared/data/constants';
import { AppValue } from '../../shared/data/types';
import { getLocalStorageData } from '../../shared/utils/localStorage';
import authReducer, { AuthAction, WorkspaceInfo } from './auth-reducer';
import { UserProfileData } from '../../pages/users/details/profile/__generated__/UserProfileData';

export type AuthState = {
  accessToken: string,
  refreshToken: string,
  user?: UserProfileData,
  workspaceId?: number,
  workspace?: WorkspaceInfo,
  workspaces?: WorkspaceInfo[],
  adminWorkspaces?: AppValue[],
  error?: string,
  isUnauthorized?: boolean,
};

export function getAccessToken(): string {
  return localStorage.getItem(AUTH_ACCESS_TOKEN) || '';
};

export function setAccessToken(token: string) {
  localStorage.setItem(AUTH_ACCESS_TOKEN, token);
};

export function getRefreshToken(): string {
  return localStorage.getItem(AUTH_REFRESH_TOKEN) || '';
};

export function setRefreshToken(token: string) {
  localStorage.setItem(AUTH_REFRESH_TOKEN, token);
};

export function getUserId(): string {
  return localStorage.getItem(AUTH_USER_ID) || '';
};

export function setUserId(userId?: number) {
  localStorage.setItem(AUTH_USER_ID, userId?.toString() || '');
};

function getInitialAuthState(): AuthState {
  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    isUnauthorized: false,
    workspaceId: getLocalStorageData<number>(AUTH_WORKSPACE, 'number'),
    workspaces: [],
    adminWorkspaces: [],
  };
};

type Context = {
  state: AuthState,
  dispatch: React.Dispatch<AuthAction>,
}

export const AuthContext = React.createContext({} as Context);

type AuthProviderProps = {
  children: React.ReactNode,
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = React.useReducer(authReducer, getInitialAuthState());
  return (
    <AuthContext.Provider value={{ state: state, dispatch: dispatch }} >
      {children}
    </AuthContext.Provider>
  )
};
