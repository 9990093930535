import { OrganizationMembershipRole } from '../../__generated__/globalTypes';
import { convertToOptions } from '../display/select/Select';

export const AUTH_ACCESS_TOKEN = 'auth-access-token';
export const AUTH_REFRESH_TOKEN = 'auth-refresh-token';
export const AUTH_USER_ID = 'auth-user-id';
export const AUTH_WORKSPACE = 'auth-workspace';
export const CONTENT_FILTERS_OPEN = 'content-filters-open';
export const ALERTS_CONTENT_FILTERS = 'alerts-content-filters';
export const SUSPECTS_CONTENT_FILTERS = 'suspects-content-filters';
export const VEHICLES_CONTENT_FILTERS = 'vehicles-content-filters';
export const USERS_CONTENT_FILTERS = 'users-content-filters';
export const SCROLL_POSITIONS = 'scroll-positions';
export const MAP_STATE = 'map-state';
export const RELOAD_TIME = 5 * 60 * 1000;
export const ERROR_GENERIC = 'Something went wrong, please try again later.';
export const AUTO_THEFT_REPORT_CONTENT_FILTERS = 'auto-theft-report-content-filters';

export const workspaceRoles = Object.values(OrganizationMembershipRole).map((value, index) => {
    return {
        id: index,
        name: value,
    };
});

export const VEHICLE_COLORS = convertToOptions([
    'Beige',
    'Black',
    'Blue',
    'Brown',
    'Burgundy',
    'Charcoal',
    'Gold',
    'Gray',
    'Green',
    'Orange',
    'Pink',
    'Purple',
    'Red',
    'Silver',
    'Tan',
    'Turquoise',
    'White',
    'Yellow',
]);

export const STATES = convertToOptions([
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]);

export const MONTHS = convertToOptions([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]);

const currentYear = new Date().getFullYear();
const years: string[] = [];
for (let i = 2023; i <= currentYear; i++) {
    years.push(i.toString());
}

export const YEARS = convertToOptions(years);

export enum ROUTE_PATH {
    DASHBOARD = '/',
    ALERTS = '/alerts',
    SUSPECTS = '/suspects',
    VEHICLES = '/vehicles',
    NOTIFICATIONS = '/notifications',
    GROUPS = '/groups',
    USERS = '/users',
    REPORTS = '/reports',
    ACCOUNT_SETTINGS = '/account/settings',
    ACCOUNT_PROFILE = '/account/profile',
    ACCOUNT_PROFILE_GROUPS = '/account/profile/groups',
    ACCOUNT_PROFILE_WORKSPACES = '/account/profile/workspaces',
    ACCOUNT_PROFILE_ASSIGNED_ALERTS = '/account/profile/assigned-alerts',
    ACCOUNT_PROFILE_AUTHORED_ALERTS = '/account/profile/authored-alerts',
    ACCOUNT_PROFILE_FOLLOWED_ALERTS = '/account/profile/followed-alerts',
    WORKSPACES = '/workspaces',
};
