import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as Logo } from '../img/treuth-logo.svg';
import { UserProfileQuery } from './__generated__/UserProfileQuery';
import { ERROR_GENERIC } from '../shared/data/constants';
import AppParagraphTitle from '../shared/display/typography/ParagraphTitle';
import { AuthContext } from './store/auth-context';
import { authLogout } from './store/auth-actions';
import { WorkspaceInfo } from './store/auth-reducer';
import Profile from '../pages/users/details/profile/Profile';
import { analyticsSetUser } from '../shared/utils/googleAnalytics';

const USER_PROFILE_QUERY = gql`
query UserProfileQuery {
  profile {
    ...UserProfileData,
    organizationMemberships {
      role,
      organization {
        id,
        name,
        photo {
          url,
        },
        lpr,
        autoTheft,
        graph,
      },
    },
  }
},
${Profile.fragments.details},
`;

export function getAlertsName(isAutoTheft: boolean) {
  return isAutoTheft ? 'Incident' : 'Alert';
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    textAlign: 'center',
  },
  logo: {
    width: 46,
    fill: theme.palette.primary.main,
    marginBottom: 10,
  },
}));

type UserProfileProps = {
  children: React.ReactNode,
};

function UserProfile({ children }: UserProfileProps) {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AuthContext);
  const { loading, error } = useQuery<UserProfileQuery>(USER_PROFILE_QUERY,
    {
      onCompleted: (data) => {
        analyticsSetUser(data.profile.id);
        const workspaces: WorkspaceInfo[] = data.profile.organizationMemberships
          .map((item) => {
            return {
              id: item.organization.id,
              role: item.role,
              name: item.organization.name,
              photo: item.organization.photo?.url,
              lpr: item.organization.lpr,
              autoTheft: item.organization.autoTheft,
            };
          });
        if (workspaces.length === 0) {
          authLogout(dispatch);
        } else {
          const workspaceId = state.workspaceId === undefined || !workspaces.some(workspace => workspace.id === state.workspaceId) ?
            workspaces[0].id : state.workspaceId;
          const workspace = workspaces.find(workspace => workspace.id === workspaceId);
          dispatch({
            type: 'UPDATE_PROFILE',
            payload: {
              user: data.profile,
              workspaces: workspaces,
              workspace,
              workspaceId,
            },
          });
        }
      },
      onError: () => { }
    });

  if (!loading && !error && state.workspaces && state.workspaces.length > 0) {
    return (
      <>
        {children}
      </>
    );
  }
  const queryContent = error ? (<AppParagraphTitle text={ERROR_GENERIC} />) : <CircularProgress size={30} />;
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item xs={3}>
        <div>
          <Logo className={classes.logo} />
        </div>
        {queryContent}
      </Grid>
    </Grid>
  );
}

export default UserProfile;
