import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import { ROUTE_PATH } from '../../shared/data/constants';
import { drawerWidth } from '../navigation/Navigation';
import PageTitle from './PageTitle';
import { LayoutContext } from '../store/layout-store';
import ProfileMenu from './ProfileMenu';
import { analyticsTrackAlerts, analyticsTrackSuspects, analyticsTrackVehicles, analyticsTrackUsers } from '../../shared/utils/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  appBar: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: 'white',
    color: theme.palette.primary.main,
    flexGrow: 1,
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px - ${theme.app.infoDrawerWidth}px)`,
      marginLeft: drawerWidth,
      marginRight: theme.app.infoDrawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    paddingRight: 0,
  },
  back: {
    marginLeft: -10,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  backIcon: {
    marginLeft: 5,
    marginRight: -5,
  },
  button: {
    textTransform: 'none',
  }
}));

type ToolbarProps = {
  onDrawerToggle: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

function AppToolbar({ onDrawerToggle }: ToolbarProps) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const { state, dispatch } = React.useContext(LayoutContext);
  const [showFilterButton, setShowFilterButton] = React.useState(false);
  const theme = useTheme();
  const fullFilterButton = useMediaQuery(theme.breakpoints.up('sm'));

  const handleFilterOpen = () => {
    switch (pathname) {
      case ROUTE_PATH.ALERTS:
        analyticsTrackAlerts('filters_open');
        break;
      case ROUTE_PATH.SUSPECTS:
        analyticsTrackSuspects('filters_open');
        break;
      case ROUTE_PATH.VEHICLES:
        analyticsTrackVehicles('filters_open');
        break;
      case ROUTE_PATH.USERS:
        analyticsTrackUsers('filters_open');
        break;
    }
    dispatch({ type: 'TOGGLE_FILTERS', payload: true });
  };

  React.useEffect(
    () => {
      setShowFilterButton(state.hasFilters === true && !state.filtersOpen);
    },
    [state]
  );

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: state.hasFilters && state.filtersOpen,
      })}
    >
      <Toolbar
        variant="dense"
        classes={
          {
            root: classes.toolbar
          }
        }
      >
        {!state.title?.previousPath &&
          <IconButton
            color="inherit"
            edge="start"
            onClick={onDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        }
        <PageTitle />
        {fullFilterButton && showFilterButton &&
          <Button
            startIcon={<FilterListIcon />}
            className={classes.button}
            onClick={handleFilterOpen}
          >
            Filter
          </Button>
        }
        {!fullFilterButton && showFilterButton &&
          <IconButton
            color="inherit"
            title="Show Filters"
            onClick={handleFilterOpen}
          >
            <FilterListIcon />
          </IconButton>
        }
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}

export default AppToolbar;
